

















































 
import { defineComponent, computed, ref } from "@vue/composition-api";
import Store from "../store/store";
import LuggageIcon from '../assets/icons/iconLuggage.vue';
import BikeIcon from '../assets/icons/iconBicycle.vue';

export default defineComponent({
  
  emits:['additional-required','additional-not-required'],
  props: {
    currentValue: Number,
  },
  components : {LuggageIcon, BikeIcon},
  setup(props, {emit}) {

    const additionalMessage = Store.appstrings.additionalRadioComponentPage.additionalMessage;

    const hasAdditional = ref(false);
    
    const decisions = [
          {
            optionValue:"Yes", 
            id:10
          },
          {
            optionValue:"No", 
            id:11
          }
      ];

    const defaultValue = 11;

    function additionalChanged(id: any) {
      if (id == 10) {
        hasAdditional.value = true;
        emit('additional-required');
      } else {
        hasAdditional.value = false;
        emit('additional-not-required');
      }
    }

    return {
      decisions,
      defaultValue,
      additionalMessage,
      additionalChanged,
      hasAdditional
    };


  }
});

