export interface StopResponse {
    code: string,
    name: string,
    ssrs: string[],
}

export interface IBookingSearchTime {
    time: string,
    date: string,
}

export enum SearchType {
    Standard = 1,
    Explorer = 2,
    Multi = 3,
    RedeemExplorer = 4,
}

export interface SelectValue  {
    value: SearchType
    display: string
}

export interface TravelTime {

    time: string,
    display: string,
}

export interface AdpositionsElements {
    Display: string,
    Value: AdpositionsEnum
}

export enum AdpositionsEnum {
    Before = 0,
    After = 1,
}

export interface TravelFare {
    name: string,
    description: string,
    quantity: number
    order?: string;
    fareClass: string;
    cardNoRequired: boolean;
    //EK - price was not being stored - needs to be string for displaying decimal points properly
    ticketPrice: string;
}

export interface SsrListType {
    ssrTypeId: string,
    description: string,
    quantity: number
}

export interface SSR {
    fareLineId: string,
    ssrType: string,
    ssrCount: number
}

export interface BikeDropdown {
    description: string,
    passanger: number,
    quantity: number,
    quantityMax: number,
    ticketType: string,
    ssrType : number,
}

export interface IWidgetSystemStore {
    origin: StopResponse,
    destination: StopResponse,
    departure: IBookingSearchTime,
    return: IBookingSearchTime,
    passengers?: TravelFare[]
    ssrListTypes?: SsrListType[]
    ssrTotals?: Array<SSR>,
    ssrSplits?: BikeDropdown[],
    wheelchairRequired:boolean,
    wheelchairFareclass: string,
    errorReason: string,
    hasSearchError: boolean,
    originOK : boolean,
    destinationOK : boolean,
    outwardDateOK : boolean,
    passengersOK : boolean,
    activeFlow: SearchType,
}