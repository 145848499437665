import { render, staticRenderFns } from "./PassengerInput.vue?vue&type=template&id=7c4da360&"
import script from "./PassengerInput.vue?vue&type=script&lang=ts&"
export * from "./PassengerInput.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  ,true
)

export default component.exports