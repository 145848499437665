


























































import { defineComponent, ref, computed, watchEffect } from '@vue/composition-api';
import { SelectValue, SearchType } from '../interface/widgetTypes';
import Store from '../store/store';
export default defineComponent({
  setup() {
    const options = ref<SelectValue[]>([
      { value: SearchType.Standard, display: 'Single/Return' },
      { value: SearchType.Multi, display: 'Multi-Journey' },
      { value: SearchType.Explorer, display: 'Explorer Pass' },
      { value: SearchType.RedeemExplorer, display: 'Reserve Explorer Pass' },
    ]);
    //if the basket is active with tickets or Redeemed explorer tickets, lock it
    const availableOptions = computed (() => {
    // eslint-disable-next-line
      let returnedOptions : any[] = [];
        options.value.forEach((option) => {
          returnedOptions.push({ value: option.value , display: option.display });
        });
      return returnedOptions;
    });

    const selected = ref<SearchType>(Store.state.activeFlow);

    watchEffect(() => {   
      //if multi/explorer/redeem is chosen, need to jump to app for now
      let start = Store.getUrl();
      if (start == '')  //localhost
        start = 'https://localhost:44346';
      if (selected.value == SearchType.RedeemExplorer) {
        let theUrl = start+'/#/widget-redeem';
        window.location.href = theUrl;
      } else if (selected.value == SearchType.Multi) {
        let theUrl = start+'/#/widget-multi';
        window.location.href = theUrl;
      } else if (selected.value == SearchType.Explorer) {
        let theUrl = start+'/#/widget-explorer';
        window.location.href = theUrl;
      } else {
        Store.state.activeFlow = selected.value;
      }
    });

    return {
      options,
      selected,
      availableOptions,
    };
  },
});
