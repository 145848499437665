


















































import { defineComponent, ref, onMounted, computed } from "@vue/composition-api";
import Store from "../store/store";
import IconSearchA from '../assets/icons/iconSearchA.vue';
import IconSearchB from '../assets/icons/iconSearchB.vue';
import { StopResponse } from "../interface/widgetTypes";

export default defineComponent({
  emits:['stop-changed'],
  props: {
    whichStop : String,
    hasIcon : Boolean,
  },
  components: {
    IconSearchA, IconSearchB
  },
  setup(props, {emit}) {

    const open = ref(false);
    const updateKey = ref(0);
    const options = ref<StopResponse[] | undefined>();
    const label = computed(() => props.whichStop);
    const isReturn = computed(() => props.whichStop == 'To');
    onMounted(() => {
      Store.getStops().then((response: StopResponse[]) => {
        options.value = response;
      });
    });

    const selected = ref('');

    const filtered = computed(() => {
      if ((selected.value == '') || (selected == null)) {
        return options.value;
      }
    
      var checkFor = selected.value.toLowerCase().trim();
      const filteredList = options.value!.filter((element) => element.name.toLowerCase().includes(checkFor));
      //const filteredList = options.value!.filter((element) => element.name.toLowerCase().includes(selected.value.toLowerCase().trim().replace(/\s+/g,' ')));

      return filteredList;
    });

    function openSearch() {
      open.value = !open.value;
      //$refs.input.focus();
    }

    function openSearchKey() {
      open.value = true;
       //this.listClicked = false;
      // EK - keypress means new destination or origin which means any error message is now not valid
      //Store.resetSearchError();    
      emit('update:selected', undefined);
    }
    
    function selectState(option) {
      open.value = false;
      selected.value = option.name;
      if (props.whichStop == 'From') {
        Store.state.origin.code = option.code;
        Store.state.origin.name = option.name;
        Store.state.origin.ssrs = option.ssrs;
      } else {
        Store.state.destination.code = option.code;
        Store.state.destination.name = option.name;
        Store.state.destination.ssrs = option.ssrs;
      }
      emit('stop-changed');
    }

    function setStop(stop) {
      selected.value = stop.name;
    }

    return {
      filtered,
      selected,
      Store,
      openSearch,
      openSearchKey,
      open,
      updateKey,
      selectState,
      label,
      isReturn,
      setStop,
    };
  },

});
