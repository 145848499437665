
<template>
  <div id="app_wrapper" ref="app_wrapper">
    <div class="mx-auto flex-grow w-12/12 text-sm">
      <div>
        <div class="search-bar__wrapper">
          <SearchSingleReturn/>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  @import './assets/main.css';
  @import './assets/tailwind.css';
</style>

<script>
import SearchSingleReturn from './components/SearchSingleReturn.vue';
import Store from './store/store';


export default {
  components: {
    SearchSingleReturn
  },
  mounted() {
    const theUrl = Store.getUrl();
    fetch(theUrl)
      .then(response => {
          if (!response.ok || response.status != 200) {
              var x = this.$refs.app_wrapper;
              x.style.display = "none";
              throw new Error(response.statusText);
            }
        });
  }
};
</script>
