import Vue from "vue";
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)

import { reactive } from '@vue/composition-api';
import { IWidgetSystemStore, SearchType, StopResponse, TravelFare, SsrListType } from '../interface/widgetTypes';
import { IProductSearch, IVSystemSwitches } from "@/interface/types";


const Store = {

    //Constants e.g. web urls
    appUrls : {
      wheelchairInfoPage : {url : 'https://uat-www.citylink.co.uk/travelling-with-citylink/assisted-travel/', 
        link : 'For more information on assisted travel with Citylink, click here'},
      explorerPassInfoPage : {url : 'https://uat-www.citylink.co.uk/travelling-with-citylink/tickets/explorer-pass/',
        link : 'For more information on using your Citylink Explorer Passes, click here'},
    },

    //EK - new section for app strings - the xxxPage refers to the vue file
    appstrings: {
      wheelchairRadioComponentPage: {
        wheelchairMessage: 'Does anyone in your party require to travel in a wheelchair for the duration of your journey?',
        wheelchairSubMessage: '(Please note: There is only one wheelchair space available on the coach. For more information click here)',
      },
      wheelchairClassPickerPage: {
        message: 'Please select the Passenger that you wish to reserve the Wheelchair Space for.',
      },
      additionalRadioComponentPage: {
        additionalMessage: 'Each passenger is entitled to store one piece of luggage in the hold. Do you require additional storage space (There may be an additional cost for this)?',
      },
    },

    appSwitches: reactive<IVSystemSwitches>({
      needsAdditionalStorage : false,
    }),
    
    state: reactive<IWidgetSystemStore>({
      origin : {
        code: '',
        name: '',
        ssrs: [],
      },
      destination : {
        code: '',
        name: '',
        ssrs: [],
      },
       departure : {
        time: '09:00:00',
        date: '',
      },
      return : {
        time: '09:00:00',
        date: '',
      },
      wheelchairRequired : false,
      wheelchairFareclass : 'NR',
      errorReason : '',
      hasSearchError : false,
      originOK : false,
      destinationOK : false,
      outwardDateOK : false,
      passengersOK : false,
      activeFlow: SearchType.Standard,
    }),

    productSearch: reactive<IProductSearch>({
      origin: {
        code: '',
        name: '',
        ssrs: [],
      },
      destination: {
        code: '',
        name: '',
        ssrs: [],
      },
      start: {
        time: '09:00:00',
        date: '',
      },
  
    }),

    resetSearchParameters() {
      this.state.origin.code = ''
      this.state.origin.name = ''
      this.state.origin.ssrs = []
      this.state.destination.code = ''
      this.state.destination.name = ''
      this.state.origin.ssrs = []
      this.state.departure.time ='09:00:00'
      this.state.departure.date = ''
      this.state.return.time = '09:00:00'
      this.state.return.date = ''
      this.state.passengers?.forEach(pass => {
        pass.quantity = 0;
      });
      this.state.ssrSplits = []
      this.state.ssrTotals = []
      this.state.wheelchairRequired = false
      this.state.wheelchairFareclass = 'NR'
    },

    //MM - used to clear return Journey when reset Return Journey button is pressed during Results  
    resetReturnJourney(){
      this.state.return.time = '09:00:00'; 
      this.state.return.date = '';
    },
  
    getUrl() {

      const theUrl = window.location.href;
  
      if (theUrl.indexOf('localhost') > 0) return 'https://uat-booking.citylink.co.uk';
      if (theUrl.indexOf('uat-') > 0) return 'https://uat-booking.citylink.co.uk';
      if (theUrl.indexOf('www.citylink.co.uk') > 0) return 'https://booking.citylink.co.uk';
      return 'https://uat-booking.citylink.co.uk';
  
    },

    async getStops(): Promise<StopResponse[]> {

      const response = await GetAvailableStops();   

      return response


    },

    async getFareData(): Promise<void> {
      getFares().then((response) => {
        Store.state.passengers = response;
      });
    },

    getSsrTypesData(): void {
      getSsrListTypes().then((response) => {
        Store.state.ssrListTypes = response;
      });
    },

    async getProductStops() {

      const stops = getProductStops();
      return stops;
  
    },

  }

  //These are stored in JourneyPlannerServices.ts in the main site but have been put in here for brevity
  const GetAvailableStops = async (): Promise<StopResponse[]> => {

    const theUrl = Store.getUrl();
    const response = await fetch(theUrl+`/api/Search/stops`)
        .then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json();
        })

    return <StopResponse[]>response;

  }

  const getFares =  async (): Promise<TravelFare[]> => {

    const theUrl = Store.getUrl();
    const response = await fetch(theUrl+`/api/Search/fares`)
    .then(response => {
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response.json();
    })
    
    return <TravelFare[]>response;
      
  }

  const getSsrListTypes =  async (): Promise<SsrListType[]> => {
    const theUrl = Store.getUrl();
    const response = await fetch(theUrl+`/api/Search/ssrTypes`)
    .then(response => {
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response.json();
    })
    
    return <SsrListType[]>response;
      
  }

  const getProductStops = async (): Promise<StopResponse[]> => {

    const response = await fetch(`/api/Search/multijourney/stops`)
        .then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json();
        })

    return <StopResponse[]>response;


}

  
  export default Store