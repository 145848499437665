





















































import  * as flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import { defineComponent, PropType, ref, watch, computed } from "@vue/composition-api";
import moment from "moment";
import TravelTimeData from "../data/travelTimeData";
import calendarIcon from "../assets/icons/calendarIcon.vue";
import chevronDownIcon from "../assets/icons/chevron-down.vue";
import { IBookingSearchTime } from "../interface/widgetTypes";
import { mixin as clickaway } from 'vue-clickaway';

export default defineComponent({
  emits:['done-button-pressed','update:selected','clear-button-pressed', 'click-away'],
  mixins: [ clickaway ],
  components: {
    flatPickr,
    calendarIcon,
    chevronDownIcon,
  },

  props: {
    doneButtonText : String,
    selected: Object as PropType<IBookingSearchTime>,
    minDate: String,
    maxDate: String,
    label: String,
    disabled: Boolean,
    clearButton: Boolean,
    hideTime : Boolean,
  },
  methods: {
    doneButtonPressed() {
      this.open = !this.open;
      this.$emit('done-button-pressed');
    },
    clearReturnButtonPressed() {
      this.open = !this.open;
      this.$emit('clear-button-pressed');
    }
  },

  setup(props, { emit }) {
   
    const maxDateMoment = computed(() => {
      if (props.maxDate != '' && props.maxDate != undefined) {
        return(moment(props.maxDate).format())
      } else {
        return(moment().add(2,'years').format());
      }
    });

    const settings = computed(() => {
      var minDateMoment  = moment(props.minDate) ?? moment();

      return {
        inline: true,
        allowInput:false,
        minDate: minDateMoment.format(),
        maxDate: maxDateMoment.value,
      };
      
    });

    const selectedConst = props.selected;

    const open = ref(false);
    const changeState = () => {
      open.value = !open.value;
    };
    const date = ref(props.selected?.date);
    const time = ref(props.selected?.time);
    const adposition = ref("");

    const hasDate = computed(() => {
      if (props.selected?.date) {
        return true;
      }
      return false;
    });
 
    //EK catch click away to close calendar. 
    // const onClickAway = (event: any) => {
    //   open.value = false;
    //    emit("click-away");
    //  }

     watch([date, time], ([date, time]) => {
      // EK - new date or time means any error message is now not valid
      //Store.resetSearchError();    
      emit("update:selected", { date, time });
    });

    const { adpositions, travelTimes } = TravelTimeData;

    function away() {
      open.value = false;
    }

    return {
      away,
      adpositions,
      travelTimes,
      settings,
      changeState,
      open,
      date,
      time,
      adposition,
      moment,
      hasDate,
      selectedConst
    };
  },
});
